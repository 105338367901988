import {Ref, computed, ref} from 'vue';
import {clamp, unlerp} from '../../../utils/math';

export type ScreenWidth = 'narrow' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

export type DeviceTypeComposition = {
  /** The current screen width. */
  screen: Ref<ScreenWidth | undefined>;

  /** True if the current screen width is in the 'narrow' breakpoint range. */
  narrow: Ref<boolean>;
  /** True if the current screen width is in the 'sm' breakpoint range. */
  sm: Ref<boolean>;
  /** True if the current screen width is in the 'md' breakpoint range. */
  md: Ref<boolean>;
  /** True if the current screen width is in the 'lg' breakpoint range. */
  lg: Ref<boolean>;
  /** True if the current screen width is in the 'xl' breakpoint range. */
  xl: Ref<boolean>;
  /** True if the current screen width is in the '2xl' breakpoint range. */
  '2xl': Ref<boolean>;

  /**
   * A value in the range [0,1] that interpolates the viewport width. It is 0 at the 'narrow'
   * breakpoint and 1 at the '2xl' breakpoint.
   */
  interpolation: Ref<number>;

  /**
   * Values in the range [0,1] that interpolate individual breakpoint ranges. They are 0 when
   * the viewport is the size where the range begin (e.g., 280 pixels for 'narrow') and 1 when
   * the viewport is at the next breakpoint. (The '2xl' value is 1 at 1920 pixels.)
   */
  interpolations: {
    narrow: Ref<number>;
    sm: Ref<number>;
    md: Ref<number>;
    lg: Ref<number>;
    xl: Ref<number>;
    '2xl': Ref<number>;
  };

  /** Returns true if the current screen width is smaller than the specified width. */
  smallerThan: (screen: ScreenWidth) => boolean;

  /** Returns true if the current screen width is smaller than or equal to the specified width. */
  smallerThanOrEqualTo: (screen: ScreenWidth) => boolean;

  /** Returns true if the current screen width is larger than the specified width. */
  largerThan: (screen: ScreenWidth) => boolean;

  /** Returns true if the current screen width is larger than or equal to the specified width. */
  largerThanOrEqualTo: (screen: ScreenWidth) => boolean;

  /** Returns true if the current viewport is smaller than the specified width (in pixels). */
  viewportSmallerThan: (pixels: number) => boolean;

  /** Returns true if the current viewport is smaller than or equal to the specified width (in pixels). */
  viewportSmallerThanOrEqualTo: (pixels: number) => boolean;

  /** Returns true if the current viewport is larger than the specified width (in pixels). */
  viewportLargerThan: (pixels: number) => boolean;

  /** Returns true if the current viewport is larger than or equal to the specified width (in pixels). */
  viewportLargerThanOrEqualTo: (pixels: number) => boolean;

  /**
   * The current width of the viewport in pixels. Ignores the vertical scrollbar (if it is present);
   * that is, it returns the width of the area to the left of the vertical scrollbar.
   */
  viewportWidth: Ref<number>;

  /**
   * The current width of the viewport in pixels. Includes the vertical scrollbar (if it is present).
   */
  viewportWidthFull: Ref<number>;

  /**
   * The current viewport height in pixels.
   */
  viewportHeight: Ref<number>;
};

const SCREEN_ORDER: {[screen: string]: number} = {
  narrow: 0,
  sm: 1,
  md: 2,
  lg: 3,
  xl: 4,
  '2xl': 5
};
export const SCREEN_ORDER_ARRAY: ReadonlyArray<ScreenWidth> = [
  'narrow',
  'sm',
  'md',
  'lg',
  'xl',
  '2xl'
];

/*
  These screen breakpoints are from Tailwind's default theme.
  See https://tailwindcss.com/docs/screens
*/
const QUERIES: {[size: string]: string} = {
  narrow: 'only screen and (max-width: 640px)',
  sm: 'only screen and (min-width: 640px) and (max-width: 768px)',
  md: 'only screen and (min-width: 768px) and (max-width: 1024px)',
  lg: 'only screen and (min-width: 1024px) and (max-width: 1280px)',
  xl: 'only screen and (min-width: 1280px) and (max-width: 1536px)',
  '2xl': 'only screen and (min-width: 1536px)'
};

function getCurrentScreen(): ScreenWidth {
  const current = Object.keys(QUERIES).find(size => {
    const query = QUERIES[size];
    return window.matchMedia(query).matches;
  });
  if (current === undefined) {
    return 'narrow';
  }
  return current as ScreenWidth;
}

const gScreen = ref<ScreenWidth | undefined>(undefined);

let gResizeEventListenerAdded = false;
const gViewportWidth = ref<number>(window.innerWidth);
const gViewportWidthFull = ref<number>(window.innerWidth);
const gViewportHeight = ref<number>(window.innerHeight);

const gInterpolation = ref<number>(0);
const gInterpolations = {
  narrow: ref(0),
  sm: ref(0),
  md: ref(0),
  lg: ref(0),
  xl: ref(0),
  '2xl': ref(0)
};

/**
 * Some components (e.g., site header, media browser) rely on knowing
 * whether the viewport is "narrow" (e.g. if the page is displayed on a
 * mobile device). This composition returns a variable (that updates
 * dynamically if the viewport dimensions change) that specifies whether
 * the viewport is narrow or not.
 */
export function useDeviceType(): Readonly<DeviceTypeComposition> {
  if (gScreen.value === undefined) {
    gScreen.value = getCurrentScreen();

    // Set up a listener for the 'change' event on the media query.
    // ### Is it better to subscribe to the window resize event?
    Object.keys(QUERIES).forEach(size => {
      const query = QUERIES[size];
      window.matchMedia(query).addEventListener('change', event => {
        if (event.matches) {
          gScreen.value = size as ScreenWidth;
        }
      });
    });
  }

  // Listen to resize event to fetch current viewport dimensions.
  const getDimensions = () => {
    // https://stackoverflow.com/questions/8339377/how-to-get-screen-width-without-minus-scrollbar
    gViewportWidth.value = document.body.clientWidth;
    gViewportWidthFull.value = window.innerWidth;

    // https://stackoverflow.com/questions/1248081/how-to-get-the-browser-viewport-dimensions
    gViewportHeight.value = window.innerHeight;

    // https://tailwindcss.com/docs/screens
    gInterpolation.value = clamp(unlerp(gViewportWidthFull.value, 640, 1920), 0, 1);
    gInterpolations.narrow.value = clamp(unlerp(gViewportWidthFull.value, 280, 640), 0, 1);
    gInterpolations.sm.value = clamp(unlerp(gViewportWidthFull.value, 640, 768), 0, 1);
    gInterpolations.md.value = clamp(unlerp(gViewportWidthFull.value, 768, 1024), 0, 1);
    gInterpolations.lg.value = clamp(unlerp(gViewportWidthFull.value, 1024, 1280), 0, 1);
    gInterpolations.xl.value = clamp(unlerp(gViewportWidthFull.value, 1280, 1536), 0, 1);
    gInterpolations['2xl'].value = clamp(unlerp(gViewportWidthFull.value, 1536, 1920), 0, 1);
  };

  if (!gResizeEventListenerAdded) {
    window.addEventListener('resize', _ => getDimensions());
    gResizeEventListenerAdded = true;
  }

  getDimensions();

  const smallerThan = (test: ScreenWidth) => {
    if (gScreen.value === undefined) {
      return false;
    }
    return SCREEN_ORDER[gScreen.value] < SCREEN_ORDER[test];
  };
  const smallerThanOrEqualTo = (test: ScreenWidth) => {
    if (gScreen.value === undefined) {
      return false;
    }
    return SCREEN_ORDER[gScreen.value] <= SCREEN_ORDER[test];
  };
  const largerThan = (test: ScreenWidth) => {
    if (gScreen.value === undefined) {
      return false;
    }
    return SCREEN_ORDER[gScreen.value] > SCREEN_ORDER[test];
  };
  const largerThanOrEqualTo = (test: ScreenWidth) => {
    if (gScreen.value === undefined) {
      return false;
    }
    return SCREEN_ORDER[gScreen.value] >= SCREEN_ORDER[test];
  };

  const viewportSmallerThan = (pixels: number) => {
    return gViewportWidth.value < pixels;
  };
  const viewportSmallerThanOrEqualTo = (pixels: number) => {
    return gViewportWidth.value <= pixels;
  };
  const viewportLargerThan = (pixels: number) => {
    return gViewportWidth.value > pixels;
  };
  const viewportLargerThanOrEqualTo = (pixels: number) => {
    return gViewportWidth.value >= pixels;
  };

  const narrow = computed(() => gScreen.value === 'narrow');
  const sm = computed(() => gScreen.value === 'sm');
  const md = computed(() => gScreen.value === 'md');
  const lg = computed(() => gScreen.value === 'lg');
  const xl = computed(() => gScreen.value === 'xl');
  const xl2 = computed(() => gScreen.value === '2xl');

  return {
    screen: gScreen,
    narrow,
    sm,
    md,
    lg,
    xl,
    '2xl': xl2,
    interpolation: gInterpolation,
    interpolations: gInterpolations,
    smallerThan,
    smallerThanOrEqualTo,
    largerThan,
    largerThanOrEqualTo,
    viewportSmallerThan,
    viewportSmallerThanOrEqualTo,
    viewportLargerThan,
    viewportLargerThanOrEqualTo,
    viewportWidth: gViewportWidth, // Does not include vertical scrollbar (if present).
    viewportWidthFull: gViewportWidthFull, // Includes vertical scrollbar (if present).
    viewportHeight: gViewportHeight
  };
}

export type ScreenWidthSwitch<T> = {
  narrow?: T;
  sm?: T;
  md?: T;
  lg?: T;
  xl?: T;
  '2xl'?: T;
};

export function completeScreenSwitch<T>(
  cfg: Readonly<ScreenWidthSwitch<T>>
): Readonly<ScreenWidthSwitch<T>> {
  const valueMap: ScreenWidthSwitch<T> = {};

  SCREEN_ORDER_ARRAY.forEach((screen, i) => {
    // We assume that the input config always has a value for the first screen width ('narrow').
    if (i === 0) {
      if (cfg[screen] === undefined) {
        throw new Error("Must provide a value for 'narrow'");
      }
      valueMap[screen] = cfg[screen];
    } else {
      /*
        For the other screens widths, use the value from the input config - if it exists.
        If not, use the value for the previous screen width. We can always assume that
        there is one, since we're stepping through the screens in order, and we know
        that there must exist one for the first ('narrow') width. (We throw an error
        otherwise, above.)
      */
      if (cfg[screen] !== undefined) {
        valueMap[screen] = cfg[screen];
      } else {
        valueMap[screen] = valueMap[SCREEN_ORDER_ARRAY[i - 1]];
      }
    }
  });

  return valueMap;
}

/**
 * This is a utility that simplifies selecting a value based on the screen width.
 * It allows you to shorten a switch like this:
 *
 *    const value = computed(() => {
 *      switch(deviceType.screen.value) {
 *        case 'narrow':
 *        case 'sm':
 *          return 'A';
 *        case 'md':
 *        case 'lg':
 *        case 'xl':
 *          return 'B';
 *        case '2xl':
 *          return 'C';
 *      }
 *    });}
 *
 * into this:
 *
 *    const value = deviceTypeSwitch<string>({
 *      'narrow': 'A',
 *      'md': 'B',
 *      '2xl': 'C'
 *    });
 *
 * The way to interpret the config object in this example is:
 *
 *    "At 'narrow', we use the value 'A'. We continue to use the value 'A' until we
 *     reach 'md', where we switch to the value 'B'. We continue to use the value 'B'
 *     until we reach '2xl', where we switch to the value 'C'."
 */
export function deviceTypeSwitch<T>(cfg: Readonly<ScreenWidthSwitch<T>>) {
  // Construct a map from screen width to config.
  const valueMap: Readonly<ScreenWidthSwitch<T>> = completeScreenSwitch<T>(cfg);
  return computed(() => {
    if (gScreen.value === undefined) {
      return valueMap['narrow'];
    }
    return valueMap[gScreen.value];
  });
}
