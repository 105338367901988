export function lerp(t: number, a: number, b: number): number {
  return a + t * (b - a);
}

export function unlerp(t: number, a: number, b: number): number {
  return (t - a) / (b - a);
}

export function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}

export function floorToNearest(v: number, step: number): number {
  return Math.floor(v / step) * step;
}
