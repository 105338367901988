import {computed, ref} from 'vue';
import {lerp} from '../../../utils/math';

// See https://stackoverflow.com/questions/13462001/ease-in-and-ease-out-animation-formula
function ease(phase: number): number {
  const sqt = phase * phase;
  return sqt / (2.0 * (sqt - phase) + 1.0);
}

/**
 * Return a Vue composition that implements simple animation,
 * e.g., for rotating spinners and similar. The animation can
 * be turned on and off by calling the setStatus() method.
 * The 'phase' property contains the animation phase, a value
 * between 0 and 1. As the animation runs, the value is increased
 * in a linear fashion.
 *
 * @param durationSec The amount of time (in seconds) it should take for
 * the animation to complete.
 * @param loop If true, then restart the animation immediately after it finishes.
 * @param fps The number of animation frames per second.
 */
export function useLinearAnimation(durationSec: number, loop: boolean, fps: number = 10) {
  const phase = ref(0);
  const easeInOut = computed(() => {
    return ease(phase.value);
  });

  let timerId: number | undefined = undefined;
  const increment = 1.0 / (fps * durationSec);
  let callback: (() => void) | undefined = undefined;

  const clear = () => {
    if (timerId !== undefined) {
      window.clearInterval(timerId);
      timerId = undefined;
      if (callback) {
        callback();
      }
    }
  };

  const start = (onEnd?: () => void) => {
    phase.value = 0;
    if (timerId === undefined) {
      callback = onEnd;
      timerId = window.setInterval(() => {
        let p = phase.value + increment;
        if (p > 1.0) {
          if (loop) {
            p = p - 1.0;
          } else {
            phase.value = 1.0;
            clear();
            return;
          }
        }
        phase.value = p;
      }, 1000 / fps);
    }
  };

  const stop = () => {
    clear();
  };

  const isRunning = () => {
    return timerId !== undefined;
  };

  return {
    phase,
    easeInOut,
    start,
    stop,
    isRunning
  };
}

export function useAnimatedToggle(durationSec: number, initStatus: boolean, fps: number = 10) {
  const phase = ref(initStatus ? 1 : 0);
  const easeInOut = computed(() => {
    return ease(phase.value);
  });
  const targetStatus = ref(initStatus);
  const running = ref(false);
  let timerId: number | undefined = undefined;
  const increment = 1.0 / (fps * durationSec);
  let callback: (() => void) | undefined = undefined;

  const clear = () => {
    running.value = false;
    if (timerId !== undefined) {
      window.clearInterval(timerId);
      timerId = undefined;
      if (callback) {
        callback();
      }
    }
  };

  const update = () => {
    if (targetStatus.value) {
      phase.value += increment;
      if (phase.value >= 1.0) {
        phase.value = 1.0;
        clear();
      }
    } else {
      phase.value -= increment;
      if (phase.value <= 0.0) {
        phase.value = 0.0;
        clear();
      }
    }
  };

  return {
    phase,
    easeInOut,
    set: (status: boolean, onEnd?: () => void) => {
      callback = onEnd;
      if (targetStatus.value !== status) {
        targetStatus.value = status;
        if (!running.value) {
          running.value = true;
          timerId = window.setInterval(update, 1000 / fps);
        }
      }
    }
  };
}

export function useAnimatedValue(durationSec: number, initValue: number, fps: number = 10) {
  let lastValue = initValue;
  let currentValue = initValue;

  const anim = useLinearAnimation(durationSec, false, fps);
  const value = computed(() => lerp(anim.phase.value, lastValue, currentValue));
  const easeInOut = computed(() => ease(value.value));

  return {
    value,
    easeInOut,
    set: (value: number, onEnd?: () => void) => {
      lastValue = currentValue;
      currentValue = value;
      anim.start(onEnd);
    }
  };
}
