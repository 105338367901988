<template>
  <!-- Sidebar menu -->
  <div :class="`flex flex-col items-center`">
    <div :style="style">
      <div class="h-20 col-start-2 flex justify-between items-end">
        <standard-heading :size="2">{{ title }}</standard-heading>
        <icon-button :size="'xl'" :icon="iconXmark" :on-click="onClose" :tooltip="'Close Menu'" />
      </div>
    </div>
    <div class="py-fl-xl" :style="style">
      <div class="col-start-2">
        <hiearchical-menu :guest="guest" :menu="menu" />
      </div>
    </div>
    <div class="py-fl-xl" :style="style">
      <div class="col-start-2">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, computed, PropType} from 'vue';
import {faXmark} from '@fortawesome/pro-light-svg-icons/faXmark';
import HiearchicalMenu from '../hierarchical-menu/HiearchicalMenu.vue';
import StandardHeading from '../../core/standard-heading/StandardHeading.vue';
import IconButton from '../../core/button/IconButton.vue';
import {HierarchicalMenuItem} from '../hierarchical-menu/hierarchical-menu-types';
import {lerp} from '../../../utils/math';
import {useDeviceType} from '../../vue-composition/device-type/device-type';

export default defineComponent({
  components: {
    StandardHeading,
    HiearchicalMenu,
    IconButton
  },
  props: {
    guest: {type: String, required: true},
    menu: {type: Object as PropType<Readonly<HierarchicalMenuItem>>, required: true},
    onClose: {type: Function as PropType<() => void>, required: true},
    title: {type: String, default: undefined},
    orientation: {type: String as PropType<'left' | 'right'>, default: 'left'}
  },
  setup() {
    const iconXmark = computed(() => faXmark);

    const deviceType = useDeviceType();
    const style = computed(() => {
      const contentArea = lerp(deviceType.interpolation.value, 180, 325);
      return `width:${contentArea}px`;
    });

    return {
      style,
      iconXmark
    };
  }
});
</script>
